export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const GET_LOGIN_ENC_REQUEST = "GET_LOGIN_ENC_REQUEST";
export const GET_LOGIN_ENC_FAILURE = "GET_LOGIN_ENC_FAILURE";
export const GET_LOGIN_ENC_SUCCESS = "GET_LOGIN_ENC_SUCCESS";
export const RESET_LOGIN_ENC_SUCCESS = "RESET_LOGIN_ENC_SUCCESS";

export const SESSON_LOGIN_REQUEST = "SESSON_LOGIN_REQUEST";
export const SESSON_LOGIN_FAILURE = "SESSON_LOGIN_FAILURE";
export const SESSON_LOGIN_SUCCESS = "SESSON_LOGIN_SUCCESS";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const CATEGORY_TYPE_REQUEST = "CATEGORY_TYPE_REQUEST";
export const CATEGORY_TYPE_FAILURE = "CATEGORY_TYPE_FAILURE";
export const CATEGORY_TYPE_SUCCESS = "CATEGORY_TYPE_SUCCESS";
export const CATEGORY_TYPE_RESET = "CATEGORY_TYPE_RESET";

export const CATEGORY_TYPE_STATE_CITY_REQUEST =
  "CATEGORY_TYPE_STATE_CITY_REQUEST";
export const CATEGORY_TYPE_STATE_CITY_FAILURE =
  "CATEGORY_TYPE_STATE_CITY_FAILURE";
export const CATEGORY_TYPE_STATE_CITY_SUCCESS =
  "CATEGORY_TYPE_STATE_CITY_SUCCESS";

export const GET_STATE_REQUEST = "GET_STATE_REQUEST";
export const GET_STATE_FAILURE = "GET_STATE_FAILURE";
export const GET_STATE_SUCCESS = "GET_STATE_SUCCESS";
export const RESET_STATE_SUCCESS = "RESET_STATE_SUCCESS";

//valuation-filter state
export const GET_VALUE_STATE_REQUEST = "GET_VALUE_STATE_REQUEST";
export const GET_VALUE_STATE_FAILURE = "GET_VALUE_STATE_FAILURE";
export const GET_VALUE_STATE_SUCCESS = "GET_VALUE_STATE_SUCCESS";
export const RESET_VALUE_STATE_SUCCESS = "RESET_VALUE_STATE_SUCCESS";
//valuation-filter city
export const GET_VALUE_CITY_REQUEST = "GET_VALUE_CITY_REQUEST";
export const GET_VALUE_CITY_FAILURE = "GET_VALUE_CITY_FAILURE";
export const GET_VALUE_CITY_SUCCESS = "GET_VALUE_CITY_SUCCESS";
export const RESET_VALUE_CITY_SUCCESS = "RESET_VALUE_CITY_SUCCESS";

export const GET_GRID_REQUEST = "GET_GRID_REQUEST";
export const GET_GRID_FAILURE = "GET_GRID_FAILURE";
export const GET_GRID_SUCCESS = "GET_GRID_SUCCESS";
export const RESET_GRID_SUCCESS = "RESET_GRID_SUCCESS";

export const GET_AGENCY_REQUEST = "GET_AGENCY_REQUEST";
export const GET_AGENCY_FAILURE = "GET_AGENCY_FAILURE";
export const GET_AGENCY_SUCCESS = "GET_AGENCY_SUCCESS";
export const RESET_AGENCY_SUCCESS = "RESET_AGENCY_SUCCESS";

export const GET_BRANCH_REQUEST = "GET_BRANCH_REQUEST";
export const GET_BRANCH_FAILURE = "GET_BRANCH_FAILURE";
export const GET_BRANCH_SUCCESS = "GET_BRANCH_SUCCESS";
export const RESET_BRANCH_SUCCESS = "RESET_BRANCH_SUCCESS";

export const GET_STATE_PROPERTY_REQUEST = "GET_STATE_PROPERTY_REQUEST";
export const GET_STATE_PROPERTY_FAILURE = "GET_STATE_PROPERTY_FAILURE";
export const GET_STATE_PROPERTY_SUCCESS = "GET_STATE_PROPERTY_SUCCESS";
export const RESET_STATE_PROPERTY_SUCCESS = "RESET_STATE_PROPERTY_SUCCESS";

export const GET_DATA_BY_RADIUS_REQUEST = "GET_DATA_BY_RADIUS_REQUEST";
export const GET_DATA_BY_RADIUS_FAILURE = "GET_DATA_BY_RADIUS_FAILURE";
export const GET_DATA_BY_RADIUS_SUCCESS = "GET_DATA_BY_RADIUS_SUCCESS";
export const RESET_DATA_BY_RADIUS_SUCCESS = "RESET_DATA_BY_RADIUS_SUCCESS";

export const GET_SEARCH_BY_REGION_REQUEST = "GET_SEARCH_BY_REGION_REQUEST";
export const GET_SEARCH_BY_REGION_FAILURE = "GET_SEARCH_BY_REGION_FAILURE";
export const GET_SEARCH_BY_REGION_SUCCESS = "GET_SEARCH_BY_REGION_SUCCESS";
export const RESET_SEARCH_BY_REGION_SUCCESS = "RESET_SEARCH_BY_REGION_SUCCESS";

export const GET_BUILDER_REQUEST = "GET_BUILDER_REQUEST";
export const GET_BUILDER_FAILURE = "GET_BUILDER_FAILURE";
export const GET_BUILDER_SUCCESS = "GET_BUILDER_SUCCESS";
export const RESET_BUILDER_SUCCESS = "RESET_BUILDER_SUCCESS";

export const GET_BUILDER_BY_LAT_LON_REQUEST = "GET_BUILDER_BY_LAT_LON_REQUEST";
export const GET_BUILDER_BY_LAT_LON_FAILURE = "GET_BUILDER_BY_LAT_LON_FAILURE";
export const GET_BUILDER_BY_LAT_LON_SUCCESS = "GET_BUILDER_BY_LAT_LON_SUCCESS";
export const RESET_BUILDER_BY_LAT_LON_SUCCESS =
  "RESET_BUILDER_BY_LAT_LON_SUCCESS";

export const GET_PROJECT_NAME_REQUEST = "GET_PROJECT_NAME_REQUEST";
export const GET_PROJECT_NAME_FAILURE = "GET_PROJECT_NAME_FAILURE";
export const GET_PROJECT_NAME_SUCCESS = "GET_PROJECT_NAME_SUCCESS";
export const RESET_PROJECT_NAME_SUCCESS = "RESET_PROJECT_NAME_SUCCESS";

export const GET_CITY_REQUEST = "GET_CITY_REQUEST";
export const GET_CITY_FAILURE = "GET_CITY_FAILURE";
export const GET_CITY_SUCCESS = "GET_CITY_SUCCESS";
export const RESET_CITY_SUCCESS = "RESET_CITY_SUCCESS";

export const GET_PROPERTY_BY_LAT_LNG_REQUEST =
  "GET_PROPERTY_BY_LAT_LNG_REQUEST";
export const GET_PROPERTY_BY_LAT_LNG_FAILURE =
  "GET_PROPERTY_BY_LAT_LNG_FAILURE";
export const GET_PROPERTY_BY_LAT_LNG_SUCCESS =
  "GET_PROPERTY_BY_LAT_LNG_SUCCESS";
export const RESET_PROPERTY_BY_LAT_LNG_SUCCESS =
  "RESET_PROPERTY_BY_LAT_LNG_SUCCESS";

export const GET_PROPERTY_STATE_CITY_REQUEST =
  "GET_PROPERTY_STATE_CITY_REQUEST";
export const GET_PROPERTY_STATE_CITY_FAILURE =
  "GET_PROPERTY_STATE_CITY_FAILURE";
export const GET_PROPERTY_STATE_CITY_SUCCESS =
  "GET_PROPERTY_STATE_CITY_SUCCESS";
export const RESET_PROPERTY_STATE_CITY_SUCCESS =
  "RESET_PROPERTY_STATE_CITY_SUCCESS";

export const GET_UNIT_TYPE_BY_LAT_LON_REQUEST =
  "GET_UNIT_TYPE_BY_LAT_LON_REQUEST";
export const GET_UNIT_TYPE_BY_LAT_LON_FAILURE =
  "GET_UNIT_TYPE_BY_LAT_LON_FAILURE";
export const GET_UNIT_TYPE_BY_LAT_LON_SUCCESS =
  "GET_UNIT_TYPE_BY_LAT_LON_SUCCESS";
export const RESET_UNIT_TYPE_BY_LAT_LON_SUCCESS =
  "RESET_UNIT_TYPE_BY_LAT_LON_SUCCESS";

export const GET_UNIT_TYPE_STATE_CITY_REQUEST =
  "GET_UNIT_TYPE_STATE_CITY_REQUEST";
export const GET_UNIT_TYPE_STATE_CITY_FAILURE =
  "GET_UNIT_TYPE_STATE_CITY_FAILURE";
export const GET_UNIT_TYPE_STATE_CITY_SUCCESS =
  "GET_UNIT_TYPE_STATE_CITY_SUCCESS";
export const RESET_UNIT_TYPE_STATE_CITY_SUCCESS =
  "RESET_UNIT_TYPE_STATE_CITY_SUCCESS";

export const GET_YEAR_BY_LAT_LON_REQUEST = "GET_YEAR_BY_LAT_LON_REQUEST";
export const GET_YEAR_BY_LAT_LON_FAILURE = "GET_YEAR_BY_LAT_LON_FAILURE";
export const GET_YEAR_BY_LAT_LON_SUCCESS = "GET_YEAR_BY_LAT_LON_SUCCESS";
export const RESET_YEAR_BY_LAT_LON_SUCCESS = "RESET_YEAR_BY_LAT_LON_SUCCESS";

export const GET_YEAR_STATE_CITY_REQUEST = "GET_YEAR_STATE_CITY_REQUEST";
export const GET_YEAR_STATE_CITY_FAILURE = "GET_YEAR_STATE_CITY_FAILURE";
export const GET_YEAR_STATE_CITY_SUCCESS = "GET_YEAR_STATE_CITY_SUCCESS";
export const RESET_YEAR_STATE_CITY_SUCCESS = "RESET_YEAR_STATE_CITY_SUCCESS";

export const GET_ZONE_CITY_REQUEST = "GET_ZONE_CITY_REQUEST";
export const GET_ZONE_CITY_FAILURE = "GET_ZONE_CITY_FAILURE";
export const GET_ZONE_CITY_SUCCESS = "GET_ZONE_CITY_SUCCESS";
export const RESET_ZONE_CITY_SUCCESS = "RESET_ZONE_CITY_SUCCESS";

export const GET_BAR_ZONE_REQUEST = "GET_BAR_ZONE_REQUEST";
export const GET_BAR_ZONE_FAILURE = "GET_BAR_ZONE_FAILURE";
export const GET_BAR_ZONE_SUCCESS = "GET_BAR_ZONE_SUCCESS";
export const RESET_BAR_ZONE_SUCCESS = "RESET_BAR_ZONE_SUCCESS";

export const GET_BAR_LOCALITY_REQUEST = "GET_BAR_LOCALITY_REQUEST";
export const GET_BAR_LOCALITY_FAILURE = "GET_BAR_LOCALITY_FAILURE";
export const GET_BAR_LOCALITY_SUCCESS = "GET_BAR_LOCALITY_SUCCESS";
export const RESET_BAR_LOCALITY_SUCCESS = "RESET_BAR_LOCALITY_SUCCESS";

export const GET_BAR_ANALYITICS_REQUEST = "GET_BAR_ANALYITICS_REQUEST";
export const GET_BAR_ANALYITICS_FAILURE = "GET_BAR_ANALYITICS_FAILURE";
export const GET_BAR_ANALYITICS_SUCCESS = "GET_BAR_ANALYITICS_SUCCESS";
export const RESET_BAR_ANALYITICS_SUCCESS = "RESET_BAR_ANALYITICS_SUCCESS";

export const GET_LOCALITY_CITY_REQUEST = "GET_LOCALITY_CITY_REQUEST";
export const GET_LOCALITY_CITY_FAILURE = "GET_LOCALITY_CITY_FAILURE";
export const GET_LOCALITY_CITY_SUCCESS = "GET_LOCALITY_CITY_SUCCESS";
export const RESET_LOCALITY_CITY_SUCCESS = "RESET_LOCALITY_CITY_SUCCESS";

export const GET_APPROVAL_BY_LAT_LON_REQUEST =
  "GET_APPROVAL_BY_LAT_LON_REQUEST";
export const GET_APPROVAL_BY_LAT_LON_FAILURE =
  "GET_APPROVAL_BY_LAT_LON_FAILURE";
export const GET_APPROVAL_BY_LAT_LON_SUCCESS =
  "GET_APPROVAL_BY_LAT_LON_SUCCESS";
export const RESET_APPROVAL_BY_LAT_LON_SUCCESS =
  "RESET_APPROVAL_BY_LAT_LON_SUCCESS";

export const GET_APPROVAL_BY_CITY_REQUEST = "GET_APPROVAL_BY_CITY_REQUEST";
export const GET_APPROVAL_BY_CITY_FAILURE = "GET_APPROVAL_BY_CITY_FAILURE";
export const GET_APPROVAL_BY_CITY_SUCCESS = "GET_APPROVAL_BY_CITY_SUCCESS";
export const RESET_APPROVAL_BY_CITY_SUCCESS = "RESET_APPROVAL_BY_CITY_SUCCESS";

export const GET_PROJECT_NAME_BY_LAT_LON_REQUEST =
  "GET_PROJECT_NAME_BY_LAT_LON_REQUEST";
export const GET_PROJECT_NAME_BY_LAT_LON_FAILURE =
  "GET_PROJECT_NAME_BY_LAT_LON_FAILURE";
export const GET_PROJECT_NAME_BY_LAT_LON_SUCCESS =
  "GET_PROJECT_NAME_BY_LAT_LON_SUCCESS";
export const RESET_PROJECT_NAME_BY_LAT_LON_SUCCESS =
  "RESET_PROJECT_NAME_BY_LAT_LON_SUCCESS";

export const GET_POLYGON_REQUEST = "GET_POLYGON_REQUEST";
export const GET_POLYGON_FAILURE = "GET_POLYGON_FAILURE";
export const GET_POLYGON_SUCCESS = "GET_POLYGON_SUCCESS";
export const RESET_POLYGON_SUCCESS = "RESET_POLYGON_SUCCESS";

export const GET_ANALYTICS_REQUEST = "GET_ANALYTICS_REQUEST";
export const GET_ANALYTICS_FAILURE = "GET_ANALYTICS_FAILURE";
export const GET_ANALYTICS_SUCCESS = "GET_ANALYTICS_SUCCESS";
export const RESET_ANALYTICS_SUCCESS = "RESET_ANALYTICS_SUCCESS";

export const GET_MONTH_BY_CITY_REQUEST = "GET_MONTH_BY_CITY_REQUEST";
export const GET_MONTH_BY_CITY_FAILURE = "GET_MONTH_BY_CITY_FAILURE";
export const GET_MONTH_BY_CITY_SUCCESS = "GET_MONTH_BY_CITY_SUCCESS";
export const RESET_MONTH_BY_CITY_SUCCESS = "RESET_MONTH_BY_CITY_SUCCESS";

export const GET_MONTH_BY_LAT_LON_REQUEST = "GET_MONTH_BY_LAT_LON_REQUEST";
export const GET_MONTH_BY_LAT_LON_FAILURE = "GET_MONTH_BY_LAT_LON_FAILURE";
export const GET_MONTH_BY_LAT_LON_SUCCESS = "GET_MONTH_BY_LAT_LON_SUCCESS";
export const RESET_MONTH_BY_LAT_LON_SUCCESS = "RESET_MONTH_BY_LAT_LON_SUCCESS";

export const GET_RATE_LIMT_BY_CITY_REQUEST = "GET_RATE_LIMT_BY_CITY_REQUEST";
export const GET_RATE_LIMT_BY_CITY_FAILURE = "GET_RATE_LIMT_BY_CITY_FAILURE";
export const GET_RATE_LIMT_BY_CITY_SUCCESS = "GET_RATE_LIMT_BY_CITY_SUCCESS";
export const RESET_RATE_LIMT_BY_CITY_SUCCESS =
  "RESET_RATE_LIMT_BY_CITY_SUCCESS";

export const GET_RATE_LIMT_BY_LAT_LON_REQUEST =
  "GET_RATE_LIMT_BY_LAT_LON_REQUEST";
export const GET_RATE_LIMT_BY_LAT_LON_FAILURE =
  "GET_RATE_LIMT_BY_LAT_LON_FAILURE";
export const GET_RATE_LIMT_BY_LAT_LON_SUCCESS =
  "GET_RATE_LIMT_BY_LAT_LON_SUCCESS";
export const RESET_RATE_LIMT_BY_LAT_LON_SUCCESS =
  "RESET_RATE_LIMT_BY_LAT_LON_SUCCESS";

export const GET_COMPLETED_BY_CITY_REQUEST = "GET_COMPLETED_BY_CITY_REQUEST";
export const GET_COMPLETED_BY_CITY_FAILURE = "GET_COMPLETED_BY_CITY_FAILURE";
export const GET_COMPLETED_BY_CITY_SUCCESS = "GET_COMPLETED_BY_CITY_SUCCESS";
export const RESET_COMPLETED_BY_CITY_SUCCESS =
  "RESET_COMPLETED_BY_CITY_SUCCESS";

export const GET_COMPLETED_BY_LAT_LON_REQUEST =
  "GET_COMPLETED_BY_LAT_LON_REQUEST";
export const GET_COMPLETED_BY_LAT_LON_FAILURE =
  "GET_COMPLETED_BY_LAT_LON_FAILURE";
export const GET_COMPLETED_BY_LAT_LON_SUCCESS =
  "GET_COMPLETED_BY_LAT_LON_SUCCESS";
export const RESET_COMPLETED_BY_LAT_LON_SUCCESS =
  "RESET_COMPLETED_BY_LAT_LON_SUCCESS";

export const GET_NO_OF_UNIT_BY_CITY_REQUEST = "GET_NO_OF_UNIT_BY_CITY_REQUEST";
export const GET_NO_OF_UNIT_BY_CITY_FAILURE = "GET_NO_OF_UNIT_BY_CITY_FAILURE";
export const GET_NO_OF_UNIT_BY_CITY_SUCCESS = "GET_NO_OF_UNIT_BY_CITY_SUCCESS";
export const RESET_NO_OF_UNIT_BY_CITY_SUCCESS =
  "RESET_NO_OF_UNIT_BY_CITY_SUCCESS";

export const GET_NO_OF_UNIT_BY_LAT_LON_REQUEST =
  "GET_NO_OF_UNIT_BY_LAT_LON_REQUEST";
export const GET_NO_OF_UNIT_BY_LAT_LON_FAILURE =
  "GET_NO_OF_UNIT_BY_LAT_LON_FAILURE";
export const GET_NO_OF_UNIT_BY_LAT_LON_SUCCESS =
  "GET_NO_OF_UNIT_BY_LAT_LON_SUCCESS";
export const RESET_NO_OF_UNIT_BY_LAT_LON_SUCCESS =
  "RESET_NO_OF_UNIT_BY_LAT_LON_SUCCESS";

export const GET_BANK_DATA_REQUEST = "GET_BANK_DATA_REQUEST";
export const GET_BANK_DATA_FAILURE = "GET_BANK_DATA_FAILURE";
export const GET_BANK_DATA_SUCCESS = "GET_BANK_DATA_SUCCESS";
export const RESET_BANK_DATA_SUCCESS = "RESET_BANK_DATA_SUCCESS";

export const GET_USER_REPORT_DATA_REQUEST = "GET_USER_REPORT_DATA_REQUEST";
export const GET_USER_REPORT_DATA_FAILURE = "GET_USER_REPORT_DATA_FAILURE";
export const GET_USER_REPORT_DATA_SUCCESS = "GET_USER_REPORT_DATA_SUCCESS";
export const RESET_USER_REPORT_DATA_SUCCESS = "RESET_USER_REPORT_DATA_SUCCESS";

export const GET_IMAGE_URL_REQUEST = "GET_IMAGE_URL_REQUEST";
export const GET_IMAGE_URL_FAILURE = "GET_IMAGE_URL_FAILURE";
export const GET_IMAGE_URL_SUCCESS = "GET_IMAGE_URL_SUCCESS";
export const RESET_IMAGE_URL_SUCCESS = "RESET_IMAGE_URL_SUCCESS";

export const GET_HISTORY_REQUEST = "GET_HISTORY_REQUEST";
export const GET_HISTORY_FAILURE = "GET_HISTORY_FAILURE";
export const GET_HISTORY_SUCCESS = "GET_HISTORY_SUCCESS";
export const RESET_HISTORY_SUCCESS = "RESET_HISTORY_SUCCESS";

export const GET_POST_REPORT_REQUEST = "GET_POST_REPORT_REQUEST";
export const GET_POST_REPORT_FAILURE = "GET_POST_REPORT_FAILURE";
export const GET_POST_REPORT_SUCCESS = "GET_POST_REPORT_SUCCESS";
export const RESET_POST_REPORT_SUCCESS = "RESET_POST_REPORT_SUCCESS";

export const GET_RECALL_REPORT_REQUEST = "GET_RECALL_REPORT_REQUEST";
export const GET_RECALL_REPORT_FAILURE = "GET_RECALL_REPORT_FAILURE";
export const GET_RECALL_REPORT_SUCCESS = "GET_RECALL_REPORT_SUCCESS";
export const RESET_RECALL_REPORT_SUCCESS = "RESET_RECALL_REPORT_SUCCESS";

export const GET_SEARCH_HISTORY_REQUEST = "GET_SEARCH_HISTORY_REQUEST";
export const GET_SEARCH_HISTORY_FAILURE = "GET_SEARCH_HISTORY_FAILURE";
export const GET_SEARCH_HISTORY_SUCCESS = "GET_SEARCH_HISTORY_SUCCESS";
export const RESET_SEARCH_HISTORY_SUCCESS = "RESET_SEARCH_HISTORY_SUCCESS";

export const GET_SEARCH_KEY_REQUEST = "GET_SEARCH_KEY_REQUEST";
export const GET_SEARCH_KEY_FAILURE = "GET_SEARCH_KEY_FAILURE";
export const GET_SEARCH_KEY_SUCCESS = "GET_SEARCH_KEY_SUCCESS";
export const RESET_SEARCH_KEY_SUCCESS = "RESET_SEARCH_KEY_SUCCESS";

export const GET_REMOVE_IMAGE_REQUEST = "GET_REMOVE_IMAGE_REQUEST";
export const GET_REMOVE_IMAGE_FAILURE = "GET_REMOVE_IMAGE_FAILURE";
export const GET_REMOVE_IMAGE_SUCCESS = "GET_REMOVE_IMAGE_SUCCESS";
export const RESET_REMOVE_IMAGE_SUCCESS = "RESET_REMOVE_IMAGE_SUCCESS";

export const GET_DISABLE_REQ_ID_REQUEST = "GET_DISABLE_REQ_ID_REQUEST";
export const GET_DISABLE_REQ_ID_FAILURE = "GET_DISABLE_REQ_ID_FAILURE";
export const GET_DISABLE_REQ_ID_SUCCESS = "GET_DISABLE_REQ_ID_SUCCESS";
export const RESET_DISABLE_REQ_ID_SUCCESS = "RESET_DISABLE_REQ_ID_SUCCESS";

export const GET_APPROVAL_REQUEST = "GET_APPROVAL_REQUEST";
export const GET_APPROVAL_FAILURE = "GET_APPROVAL_FAILURE";
export const GET_APPROVAL_SUCCESS = "GET_APPROVAL_SUCCESS";
export const RESET_APPROVAL_SUCCESS = "RESET_APPROVAL_SUCCESS";

export const GET_BUILDING_NAME_REQUEST = "GET_BUILDING_NAME_REQUEST";
export const GET_BUILDING_NAME_FAILURE = "GET_BUILDING_NAME_FAILURE";
export const GET_BUILDING_NAME_SUCCESS = "GET_BUILDING_NAME_SUCCESS";
export const RESET_BUILDING_NAME_SUCCESS = "RESET_BUILDING_NAME_SUCCESS";

export const REQUEST_GET_VOICE_RECORD = "REQUEST_GET_VOICE_RECORD";
export const SUCCESS_GET_VOICE_RECORD = "SUCCESS_GET_VOICE_RECORD";
export const FAILED_GET_VOICE_RECORD = "FAILED_GET_VOICE_RECORD";

export const REQUEST_GET_VIDEO_RECORD = "REQUEST_GET_VIDEO_RECORD";
export const SUCCESS_GET_VIDEO_RECORD = "SUCCESS_GET_VIDEO_RECORD";
export const FAILED_GET_VIDEO_RECORD = "FAILED_GET_VIDEO_RECORD";

export const REQUEST_GET_GEO_LOCATIONS = "REQUEST_GET_GEO_LOCATIONS";
export const SUCCESS_GET_GEO_LOCATIONS = "SUCCESS_GET_GEO_LOCATIONS";
export const FAILED_GET_GEO_LOCATIONS = "FAILED_GET_GEO_LOCATIONS";
export const SET_EMPTY_LOCATION = "SET_EMPTY_LOCATION";

export const REQUEST_GET_AGENT_DATA = "REQUEST_GET_AGENT_DATA";
export const SUCCESS_GET_AGENT_DATA = "SUCCESS_GET_AGENT_DATA";
export const FAILED_GET_AGENT_DATA = "FAILED_GET_AGENT_DATA";

export const GET_LOCATION_TRAKING_REQUEST = "GET_LOCATION_TRAKING_REQUEST";
export const GET_LOCATION_TRAKING_FAILURE = "GET_LOCATION_TRAKING_FAILURE";
export const GET_LOCATION_TRAKING_SUCCESS = "GET_LOCATION_TRAKING_SUCCESS";
export const RESET_LOCATION_TRAKING_SUCCESS = "RESET_LOCATION_TRAKING_SUCCESS";

export const LOCATION_UPDATE_FAILURE = "LOCATION_UPDATE_FAILURE";
export const LOCATION_UPDATE_SUCCESS = "LOCATION_UPDATE_SUCCESS";
export const LOCATION_UPDATE_REQUEST = "LOCATION_UPDATE_REQUEST";

export const GET_PPIPINCODE_FAILURE = "GET_PPIPINCODE_FAILURE";
export const GET_PPIPINCODE_SUCCESS = "GET_PPIPINCODE_SUCCESS";
export const GET_PPIPINCODE_REQUEST = "GET_PPIPINCODE_REQUEST";
export const RESET_PPIPINCODE_SUCCESS = "RESET_PPIPINCODE_SUCCESS";

export const GET_PPIPROPRTY_FAILURE = "GET_PPIPROPRTY_FAILURE";
export const GET_PPIPROPRTY_SUCCESS = "GET_PPIPROPRTY_SUCCESS";
export const GET_PPIPROPRTY_REQUEST = "GET_PPIPROPRTY_REQUEST";
export const RESET_PPIPROPRTY_SUCCESS = "RESET_PPIPROPRTY_SUCCESS";

export const GET_PPIUNIT_FAILURE = "GET_PPIUNIT_FAILURE";
export const GET_PPIUNIT_SUCCESS = "GET_PPIUNIT_SUCCESS";
export const GET_PPIUNIT_REQUEST = "GET_PPIUNIT_REQUEST";
export const RESET_PPIUNIT_SUCCESS = "RESET_PPIUNIT_SUCCESS";

export const GET_PPIAGE_FAILURE = "GET_PPIAGE_FAILURE";
export const GET_PPIAGE_SUCCESS = "GET_PPIAGE_SUCCESS";
export const GET_PPIAGE_REQUEST = "GET_PPIAGE_REQUEST";
export const RESET_PPIAGE_SUCCESS = "RESET_PPIAGE_SUCCESS";

export const GET_PPIANALYTICS_FAILURE = "GET_PPIANALYTICS_FAILURE";
export const GET_PPIANALYTICS_SUCCESS = "GET_PPIANALYTICS_SUCCESS";
export const GET_PPIANALYTICS_REQUEST = "GET_PPIANALYTICS_REQUEST";
export const RESET_PPIANALYTICS_SUCCESS = "RESET_PPIANALYTICS_SUCCESS";

export const GET_CREATE_CALL_REQUEST = "GET_CREATE_CALL_REQUEST";
export const GET_CREATE_CALL_SUCCESS = "GET_CREATE_CALL_SUCCESS";
export const GET_CREATE_CALL_FAILURE = "GET_CREATE_CALL_FAILURE";
export const RESET_CREATE_CALL_SUCCESS = "RESET_CREATE_CALL_SUCCESS";

export const CANCEL_CALL_REQUEST = "CANCEL_CALL_REQUEST";
export const CANCEL_CALL_SUCCESS = "CANCEL_CALL_SUCCESS";
export const CANCEL_CALL_FAILURE = "CANCEL_CALL_FAILURE";

//branch constants
export const GET_BRANCH_STATES_FAILURE = "GET_BRANCH_STATES_FAILURE";
export const GET_BRANCH_STATES_SUCCESS = "GET_BRANCH_STATES_SUCCESS";
export const GET_BRANCH_STATES_REQUEST = "GET_BRANCH_STATES_REQUEST";
export const RESET_BRANCH_STATES_SUCCESS = "GET_BRANCH_STATES_SUCCESS";

export const GET_BRANCH_CITIES_FAILURE = "GET_BRANCH_CITIES_FAILURE";
export const GET_BRANCH_CITIES_SUCCESS = "GET_BRANCH_CITIES_SUCCESS";
export const GET_BRANCH_CITIES_REQUEST = "GET_BRANCH_CITIES_REQUEST";
export const RESET_BRANCH_CITIES_SUCCESS = "GET_BRANCH_CITIES_SUCCESS";

export const GET_BRANCH_NAMES_FAILURE = "GET_BRANCH_NAMES_FAILURE";
export const GET_BRANCH_NAMES_SUCCESS = "GET_BRANCH_NAMES_SUCCESS";
export const GET_BRANCH_NAMES_REQUEST = "GET_BRANCH_NAMES_REQUEST";
export const RESET_BRANCH_NAMES_SUCCESS = "GET_BRANCH_NAMES_SUCCESS";

export const GET_BRANCH_REPORT_FAILURE = "GET_BRANCH_REPORT_FAILURE";
export const GET_BRANCH_REPORT_SUCCESS = "GET_BRANCH_REPORT_SUCCESS";
export const GET_BRANCH_REPORT_REQUEST = "GET_BRANCH_REPORT_REQUEST";
export const RESET_BRANCH_REPORT_SUCCESS = "GET_BRANCH_REPORT_SUCCESS";

//dates
export const GET_BRANCH_AGENCY_DATES_FAILURE =
  "GET_BRANCH_AGENCY_DATES_FAILURE";
export const GET_BRANCH_AGENCY_DATES_SUCCESS =
  "GET_BRANCH_AGENCY_DATES_SUCCESS";
export const GET_BRANCH_AGENCY_DATES_REQUEST =
  "GET_BRANCH_AGENCY_DATES_REQUEST";
export const RESET_BRANCH_AGENCY_DATES_SUCCESS =
  "GET_BRANCH_AGENCY_DATES_SUCCESS";

//agency constants
export const GET_AGENCY_STATES_FAILURE = "GET_AGENCY_STATES_FAILURE";
export const GET_AGENCY_STATES_SUCCESS = "GET_AGENCY_STATES_SUCCESS";
export const GET_AGENCY_STATES_REQUEST = "GET_AGENCY_STATES_REQUEST";
export const RESET_AGENCY_STATES_SUCCESS = "GET_AGENCY_STATES_SUCCESS";

export const GET_AGENCY_CITIES_FAILURE = "GET_AGENCY_CITIES_FAILURE";
export const GET_AGENCY_CITIES_SUCCESS = "GET_AGENCY_CITIES_SUCCESS";
export const GET_AGENCY_CITIES_REQUEST = "GET_AGENCY_CITIES_REQUEST";
export const RESET_AGENCY_CITIES_SUCCESS = "GET_AGENCY_CITIES_SUCCESS";

export const GET_AGENCY_NAMES_FAILURE = "GET_AGENCY_NAMES_FAILURE";
export const GET_AGENCY_NAMES_SUCCESS = "GET_AGENCY_NAMES_SUCCESS";
export const GET_AGENCY_NAMES_REQUEST = "GET_AGENCY_NAMES_REQUEST";
export const RESET_AGENCY_NAMES_SUCCESS = "GET_AGENCY_NAMES_SUCCESS";

export const GET_AGENCY_REPORT_FAILURE = "GET_AGENCY_REPORT_FAILURE";
export const GET_AGENCY_REPORT_SUCCESS = "GET_AGENCY_REPORT_SUCCESS";
export const GET_AGENCY_REPORT_REQUEST = "GET_AGENCY_REPORT_REQUEST";
export const RESET_AGENCY_REPORT_SUCCESS = "GET_AGENCY_REPORT_SUCCESS";
export const GET_REPORT_YEAR_FAILURE = "GET_REPORT_YEAR_FAILURE";
export const GET_REPORT_YEAR_SUCCESS = "GET_REPORT_YEAR_SUCCESS";
export const GET_REPORT_YEAR_REQUEST = "GET_REPORT_YEAR_REQUEST";

export const GET_ANALATICS_REPORT_FAILURE = "GET_ANALATICS_REPORT__FAILURE";
export const GET_ANALATICS_REPORT_SUCCESS = "GET_ANALATICS_REPORT_SUCCESS";
export const GET_ANALATICS_REPORT_REQUEST = "GET_ANALATICS_REPORT_REQUEST";

//zones
export const GET_AGENCY_ZONES_FAILURE = "GET_AGENCY_ZONES_FAILURE";
export const GET_AGENCY_ZONES_SUCCESS = "GET_AGENCY_ZONES_SUCCESS";
export const GET_AGENCY_ZONES_REQUEST = "GET_AGENCY_ZONES_REQUEST";
export const RESET_AGENCY_ZONES_SUCCESS = "RESET_AGENCY_ZONES_SUCCESS";

//state by report
export const GET_STATE_BY_CITY_REQUEST = "GET_STATE_BY_CITY_REQUEST";
export const GET_STATE_BY_CITY_SUCCESS = "GET_STATE_BY_CITY_SUCCESS";
export const GET_STATE_BY_CTY_FAILURE = "GET_STATE_BY_CTY_FAILURE";

//report
export const GET_BRANCH_REPORT_DATA_REQUEST = "GET_BRANCH_REPORT_DATA_REQUEST";
export const GET_BRANCH_REPORT_DATA_SUCCESS = "GET_BRANCH_REPORT_DATA_SUCCESS";
export const GET_BRANCH_REPORT_DATA_FAILURE = "GET_BRANCH_REPORT_DATA_FAILURE";

export const GET_AGENCY_REPORT_DATA_REQUEST = "GET_AGENCY_REPORT_DATA_REQUEST";
export const GET_AGENCY_REPORT_DATA_SUCCESS = "GET_AGENCY_REPORT_DATA_SUCCESS";
export const GET_AGENCY_REPORT_DATA_FAILURE = "GET_AGENCY_REPORT_DATA_FAILURE";

export const GET_ANALYTICS_DATA_FROM_CITY = "GET_ANALYTICS_DATA_FROM_CITY";
export const GET_AGENCY_BRANCH_COUNT = "GET_AGENCY_BRANCH_COUNT";
export const GET_AGENCY_BRANCH_COUNT_FAILURE =
  "GET_AGENCY_BRANCH_COUNT_FAILURE";

export const GET_SERVEY_REQUEST = "GET_SERVEY_REQUEST";
export const GET_SERVEY_SUCCESS = "GET_SERVEY_SUCCESS";
export const GET_SERVEY_FAILURE = "GET_SERVEY_FAILURE";

export const GET_SERVEY_REPORT_REQUEST = "GET_SERVEY_REPORT_REQUEST";
export const GET_SERVEY_REPORT_SUCCESS = "GET_SERVEY_REPORT_SUCCESS";
export const GET_SERVEY_REPORT_FAILURE = "GET_SERVEY_REPORT_FAILURE";

export const GET_SERVEY_REPORTS_REQUEST = "GET_SERVEY_REPORTS_REQUEST";
export const GET_SERVEY_REPORTS_SUCCESS = "GET_SERVEY_REPORTS_SUCCESS";
export const GET_SERVEY_REPORTS_FAILURE = "GET_SERVEY_REPORTS_FAILURE";

export const INSERT_USER_REQUEST = "INSERT_USER_REQUEST";
export const INSERT_USER_SUCCESS = "INSERT_USER_SUCCESS";
export const INSERT_USER_FAILURE = "INSERT_USER_FAILURE";

export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

export const SET_PRO_UNI_TYPE = "SET_PRO_UNI_TYPE";

export const GET_REPORT_REQUEST = "GET_REPORT_REQUEST";
export const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS";
export const GET_REPORT_FAILURE = "GET_REPORT_FAILURE";

// EMPLOYEE ANALYTICS
export const GET_EMPLOYEE_ANALYTIC_REQUEST = "GET_EMPLOYEE_ANALYTIC_REQUEST";
export const GET_EMPLOYEE_ANALYTIC_SUCCESS = "GET_EMPLOYEE_ANALYTIC_SUCCESS";
export const GET_EMPLOYEE_ANALYTIC_FAILURE = "GET_EMPLOYEE_ANALYTIC_FAILURE";
export const GET_EMPLOYEE_ANALYTIC_DETAILS_REQUEST = "GET_EMPLOYEE_ANALYTIC_DETAILS_REQUEST";
export const GET_EMPLOYEE_ANALYTIC_DETAILS_SUCCESS = "GET_EMPLOYEE_ANALYTIC_DETAILS_SUCCESS";
export const GET_EMPLOYEE_ANALYTIC_DETAILS_FAILURE = "GET_EMPLOYEE_ANALYTIC_DETAILS_FAILURE";
